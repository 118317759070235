// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { versions } from './versions';

const version = versions ? versions : null;

export const environment = {
envname: 'UAT',
baseUrl: 'https://uateproveapi.cognia.org/formative-assessments-api/v1',
customerUrl: 'https://uateproveapi.cognia.org/customer-api/v1',
userUrl: '',
myJourneyUrl: 'https://uatmyjourney.cognia.org/',
resourcesUrl: 'https://assessment.onlinehelp.cognia.org/about-formative',
    applicationName: 'assessment',
production: true,
    version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
